<template>
	<div>
		<!-- Page container -->
		<div class="page-container">
			<!-- Page content -->
			<div class="page-content">
				<!-- Main content -->
				<div class="content-wrapper">
					<!-- Content area -->
					<div class="content">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	computed: {},
	components: {},
};
</script>
